const RESOURCE_ROUTES = '/resource-routes';

export default {
  activities: `${RESOURCE_ROUTES}/activities`,
  aiCookie: `${RESOURCE_ROUTES}/AI-cookie`,
  aiTopicCopilot: `${RESOURCE_ROUTES}/ai-topicCopilot`,
  authTokens: `${RESOURCE_ROUTES}/auth-tokens`,
  coLeads: `${RESOURCE_ROUTES}/co-leads`,
  comment: `${RESOURCE_ROUTES}/comment`,
  deleteBatchResponses: `${RESOURCE_ROUTES}/delete-batch-responses`,
  deleteGroup: `${RESOURCE_ROUTES}/delete-group`,
  disconnectCanvas: `${RESOURCE_ROUTES}/disconnect-canvas`,
  downloadBatchResponses: `${RESOURCE_ROUTES}/download-batch-responses`,
  downloadData: `${RESOURCE_ROUTES}/download-data`,
  duplicateGroup: `${RESOURCE_ROUTES}/duplicate-group`,
  emailSignup: `${RESOURCE_ROUTES}/emailSignup`,
  exportData: `${RESOURCE_ROUTES}/export-data`,
  feedbacks: `${RESOURCE_ROUTES}/feedbacks`,
  getFileuploadToken: `${RESOURCE_ROUTES}/get-fileupload-token`,
  googleRoster: `${RESOURCE_ROUTES}/google-roster`,
  gridToken: `${RESOURCE_ROUTES}/grid-token`,
  joinCode: `${RESOURCE_ROUTES}/join-code`,
  like: `${RESOURCE_ROUTES}/like`,
  manageTopics: `${RESOURCE_ROUTES}/manage-topics`,
  markAsViewed: `${RESOURCE_ROUTES}/markAsViewed`,
  media: `${RESOURCE_ROUTES}/media`,
  members: `${RESOURCE_ROUTES}/members`,
  mixtapeJoinCode: `${RESOURCE_ROUTES}/mixtape-joinCode`,
  mixtapes: `${RESOURCE_ROUTES}/mixtapes`,
  myGroups: `${RESOURCE_ROUTES}/my-groups`,
  myTopics: `${RESOURCE_ROUTES}/my-topics`,
  notifications: `${RESOURCE_ROUTES}/notifications`,
  postToTopic: `${RESOURCE_ROUTES}/post-to-topic`,
  recorder: `${RESOURCE_ROUTES}/recorder`,
  remove: `${RESOURCE_ROUTES}/remove`,
  reply: `${RESOURCE_ROUTES}/reply`,
  report: `${RESOURCE_ROUTES}/report`,
  shareToken: `${RESOURCE_ROUTES}/share-token`,
  signOut: `${RESOURCE_ROUTES}/sign-out`,
  subscribe: `${RESOURCE_ROUTES}/subscribe`,
  topic: `${RESOURCE_ROUTES}/topic`,
  topicTemplates: `${RESOURCE_ROUTES}/topic-templates`,
  transcripts: `${RESOURCE_ROUTES}/transcripts`,
  updateState: `${RESOURCE_ROUTES}/update-state`,
  uploads: `${RESOURCE_ROUTES}/uploads`,
  userPreferences: `${RESOURCE_ROUTES}/user-preferences`,
  username: `${RESOURCE_ROUTES}/username`,
  validators: `${RESOURCE_ROUTES}/validators`,
  webSocketsToken: `${RESOURCE_ROUTES}/webSocketsToken`,
} as const;
